import * as React from "react"


const NotFoundPage = () => (
  <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
    <div className="text-center">
      <p className="text-base font-semibold text-crows-red">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-crows-red sm:text-5xl">Page not found</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="../#"
          className="rounded-md bg-crows-red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-crows-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          Go back home
        </a>
      </div>
    </div>
  </main>
)

export default NotFoundPage
